<template>
   <div id="main-wrapper">
      <!-- <Container/> -->
      <section class="blockElement pb-2 pb-md-4 pb-lg-5">
         <div class="container ">
            <div class="row justify-content-between align-items-center text-center text-md-start">
               <div class="col-12 col-md-6">
                  <h1 class="mb-0">{{contentlist.about_us_content1}} <span>{{contentlist.about_us_content2}}</span> {{contentlist.about_us_content3}} <span>{{contentlist.about_us_content4}}</span></h1>
               </div>
               <div class="col-12 col-md-6 mt-3 mt-md-0">
                  <p class="d-block mb-0">{{contentlist.about_us_content5}}</p>
               </div>
            </div>
            <div class="row mt-3 mt-md-5">
               <div class="col-12">
                  <img src="/assets/images/about-story.webp" alt="About Us Story" class="img-fluid" />
               </div>
            </div>
         </div>
      </section>
      <section class="blockElement pt-0">
         <div class="container">
            <div class="tab d-flex justify-content-center hideMobile">
               <ul class="nav nav-pills">
                  <li class="nav-item">
                     <a class="nav-link" href="javascript:void(0);" :class="[{'active':tab == 1}]" @click.prevent="tab = 1">{{contentlist.about_us_content6}}</a>
                  </li>
                  <li class="nav-item">
                     <a class="nav-link" href="javascript:void(0);" :class="[{'active':tab == 2}]" @click.prevent="tab = 2">{{contentlist.about_us_content7}}</a>
                  </li>
                  <li class="nav-item">
                     <a class="nav-link" href="javascript:void(0);" :class="[{'active':tab == 3}]" @click.prevent="tab = 3">{{contentlist.about_us_content8}}</a>
                  </li>
               </ul>
            </div>
            <div class="tabContent mt-2 mt-lg-5">
               <div class="showTabs-in-mob" :class="[{'active':tab == 1}]">
                  <a class="nav-link round-sm px-3 py-2 semibold" href="javascript:void(0);" @click.prevent="tab = 1">
                     {{contentlist.about_us_content6}}
                     <vue-feather size="25px" type="chevron-down"></vue-feather>
                  </a>
               </div>
               <div v-if="tab == 1" class="pt-4 pt-lg-0">
                  <div class="row align-items-center">
                     <div class="col-md-5">
                        <img src="/assets/images/about-who.webp" alt="Who We Are" class="img-fluid">
                     </div>
                     <div class="col-md-7">
                        <div class="ps-0 ps-md-5">
                           <ul class="listIcon mt-4">
                              <li>{{contentlist.about_us_content9}}</li>
                              <li>{{contentlist.about_us_content10}}</li>
                              <li>{{contentlist.about_us_content11}}</li>
                           </ul>
                           <div class="d-block text-left mt-3">
                              <router-link :to="entityPath+'/merchant-solution'" class="themeBtn medium">{{contentlist.read_more_content}}</router-link>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="showTabs-in-mob pt-4 pb-lg-0" :class="[{'active':tab == 2}]">
                  <a class="nav-link round-sm px-3 py-2 semibold" href="javascript:void(0);" @click.prevent="tab = 2">
                     {{contentlist.about_us_content7}}
                     <vue-feather size="25px" type="chevron-down"></vue-feather>
                  </a>
               </div>
               <div v-if="tab == 2" class="pt-4 pt-lg-0">
                  <div class="row justify-content-center">
                     <div class="col-md-6 col-lg-4 d-flex justify-content-center">
                        <div class="merchantCapitalBox text-center">
                           <div class="greyBgIcon rounded-pill mt-3 mt-lg-5 m-auto d-flex align-items-center justify-content-center lightgreybg">
                              <img src="/assets/images/responsible-tactics.webp" alt="Responsible Business Tactics" class="img-fluid">
                           </div>
                           <p class="m-0 lh-base pt-4 semibold primarycolor">{{contentlist.about_us_content12}}</p>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4 d-flex justify-content-center">
                        <div class="merchantCapitalBox text-center">
                           <div class="greyBgIcon rounded-pill mt-3 mt-lg-5 m-auto d-flex align-items-center justify-content-center lightgreybg">
                              <img src="/assets/images/strong-business-ethics.webp" alt="Strong Business Ethics" class="img-fluid">
                           </div>
                           <p class="m-0 lh-base pt-4 semibold primarycolor">{{contentlist.about_us_content13}}</p>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4 d-flex justify-content-center">
                        <div class="merchantCapitalBox text-center">
                           <div class="greyBgIcon rounded-pill mt-3 mt-lg-5 m-auto d-flex align-items-center justify-content-center lightgreybg">
                              <img src="/assets/images/high-technology.webp" alt="Responsible Business Tactics" class="img-fluid">
                           </div>
                           <p class="m-0 lh-base pt-4 semibold primarycolor">{{contentlist.about_us_content14}}</p>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4 d-flex justify-content-center">
                        <div class="merchantCapitalBox text-center">
                           <div class="greyBgIcon rounded-pill mt-3 mt-lg-5 m-auto d-flex align-items-center justify-content-center lightgreybg">
                              <img src="/assets/images/compliant-regulated.webp" alt="Compliant and Registered" class="img-fluid">
                           </div>
                           <p class="m-0 lh-base pt-4 semibold primarycolor">{{contentlist.about_us_content15}}</p>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4 d-flex justify-content-center">
                        <div class="merchantCapitalBox text-center">
                           <div class="greyBgIcon rounded-pill mt-3 mt-lg-5 m-auto d-flex align-items-center justify-content-center lightgreybg">
                              <img src="/assets/images/defense-strategy.webp" alt="Secured and Anonymous" class="img-fluid">
                           </div>
                           <p class="m-0 lh-base pt-4 semibold primarycolor">{{contentlist.about_us_content16}}</p>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4 d-flex justify-content-center">
                        <div class="merchantCapitalBox text-center">
                           <div class="greyBgIcon rounded-pill mt-3 mt-lg-5 m-auto d-flex align-items-center justify-content-center lightgreybg">
                              <img src="/assets/images/work-team.webp" alt="We work as a Team" class="img-fluid">
                           </div>
                           <p class="m-0 lh-base pt-4 semibold primarycolor">{{contentlist.about_us_content17}}</p>
                        </div>
                     </div>
                  </div>
                  <div class="d-block text-center mt-4 mt-lg-5">

                     <router-link :to="entityPath+'/merchant-solution'" class="themeBtn medium">{{contentlist.find_out_more_content}}</router-link>
                     
                     
                  
                  
                  </div>
               </div>
               <div class="showTabs-in-mob pt-4 pb-lg-0" :class="[{'active':tab == 3}]">
                  <a class="nav-link round-sm px-3 py-2 semibold" href="javascript:void(0);" @click.prevent="tab = 3">
                     {{contentlist.about_us_content8}}
                     <vue-feather size="25px" type="chevron-down"></vue-feather>
                  </a>
               </div>
               <div v-if="tab == 3" class="pt-4 pt-lg-0">
                  <p class="primarycolor text-center semibold">{{contentlist.about_us_content18}}</p>
                  <div class="row mt-4 mt-md-5">
                     <div class="col-md-4 WeStandBox">
                        <div class="WeStandBoxIn borderedR">
                           <div class="greyBgIcon rounded-pill d-flex align-items-center justify-content-center lightgreybg">
                              <img src="/assets/images/respect.webp" alt="Responsible Business Tactics" class="img-fluid">
                           </div>
                           <h6 class="m-0 lh-base pt-4 semibold primarycolor">{{contentlist.about_us_content19}}</h6>
                           <p class="pt-2 mb-0">{{contentlist.about_us_content20}}</p>
                        </div>
                     </div>
                     <div class="col-md-4 WeStandBox">
                        <div class="WeStandBoxIn borderedR">
                           <div class="greyBgIcon rounded-pill d-flex align-items-center justify-content-center lightgreybg">
                              <img src="/assets/images/back.webp" alt="Responsible Business Tactics" class="img-fluid">
                           </div>
                           <h6 class="m-0 lh-base pt-4 semibold primarycolor">{{contentlist.about_us_content21}}</h6>
                           <p class="pt-2 mb-0">{{contentlist.about_us_content22}}</p>
                        </div>
                     </div>
                     <div class="col-md-4 WeStandBox">
                        <div class="WeStandBoxIn">
                           <div class="greyBgIcon rounded-pill d-flex align-items-center justify-content-center lightgreybg">
                              <img src="/assets/images/do-right.webp" alt="Responsible Business Tactics" class="img-fluid">
                           </div>
                           <h6 class="m-0 lh-base pt-4 semibold primarycolor">{{contentlist.about_us_content23}}</h6>
                           <p class="pt-2 mb-0">{{contentlist.about_us_content24}}</p>
                        </div>
                     </div>
                  </div>
                  <h6 class="fst-italic text-center mt-4">{{contentlist.about_us_content25}}</h6>
                  <div class="d-block text-center mt-4 mt-lg-5">
                    
                     <router-link :to="entityPath+'/merchant-solution'" class="themeBtn medium">{{contentlist.find_out_more_content}}</router-link>


                    
                  
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="blockElement lightgreybg pb-5">
         <div class="container">
            <div class="text-center">
               <h2 class="mb-0">{{contentlist.about_us_content26}}</h2>
               <p class="d-block mt-4 mb-0">{{contentlist.about_us_content27}}</p>
               <div class="row align-items-center mt-5 pt-4">
                  <div class="col-12 col-sm-6 col-md-3 pb-5 pb-md-0"><img src="/assets/images/ifx-expo-international.webp" alt="ifx expo international" class="img-fluid"></div>
                  <div class="col-12 col-sm-6 col-md-3 pb-5 pb-md-0"><img src="/assets/images/forex-expo-dubai.webp" alt="forex expo dubai" class="img-fluid"></div>
                  <div class="col-12 col-sm-6 col-md-3 pb-4 pb-md-0"><img src="/assets/images/london-summit.webp" alt="finance magnates london summit" class="img-fluid"></div>
                  <div class="col-12 col-sm-6 col-md-3 pb-4 pb-md-0"><img src="/assets/images/ifx-expo-dubai.webp" alt="ifx expo dubai" class="img-fluid"></div>
               </div>
               <div class="row justify-content-center mt-5 pt-0 mt-md-5">
                  <div class="col-12 col-lg-11">
                     <h6 class="text-center mb-4 mb-md-5 bodyfontcolor medium">{{contentlist.about_us_content28}}</h6>
                     <ul class="clientLogo d-flex flex-wrap align-items-center justify-content-center mb-0">
                        <li class="pe-5"><img src="/assets/images/finance-magnates.webp" alt="Client Logo 1" title="Client Logo 1" width="180" height="75" class="img-fluid"></li>
                        <li class="pe-5"><img src="/assets/images/finance-feeds.png" alt="Client Logo 2" title="Client Logo 2" width="180" height="68" class="img-fluid"></li>
                        <li class="pe-5"><img src="/assets/images/coin-pedia.png" alt="Client Logo 3" title="Client Logo 3" width="190" height="44" class="img-fluid"></li>
                        <li class="pe-5"><img src="/assets/images/fintech-news.webp" alt="Client Logo 4" title="Client Logo 4" width="180" height="23" class="img-fluid"></li>
                        <li><img src="/assets/images/coin-world-story.png" alt="Client Logo 5" title="Client Logo 5" width="180" height="39" class="img-fluid"></li>
                     </ul>
                     <router-link :to="entityPath+'/press-release'" class="themeBtn medium mt-3 mt-lg-4">{{contentlist.read_more_content}}</router-link>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="blockElement ourMotto position-relative">
         <div class="container">
            <div class="row justify-content-between align-items-center">
               <div class="col-12 col-md-6 text-center text-md-start">
                  <h6 class="bodyfontcolor regular mb-0">{{contentlist.about_us_content29}}</h6>
                  <h1 class="mb-0">{{contentlist.about_us_content30}}</h1>
               </div>
               <div class="col-12 col-md-6 text-center text-md-start">
                  <p class="d-block mb-0">{{contentlist.about_us_content31}}</p>
               </div>
            </div>
         </div>
      </section>
      <!-- <Footer /> -->
      <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 text-center primarybgSection">
                  <div class="criptoIcon">
                     <span class="left-tp one"><img src="/assets/images/onHover-2.webp" alt="Icon" title="Crypto 1" width="77" height="90" class="img-fluid"></span>
                     <span class="left-tp tow"><img src="/assets/images/onHover-1.webp" alt="Icon" title="Crypto 2" width="98" height="98" class="img-fluid"></span>
                     <span class="left-tp three"><img src="/assets/images/onHover-3.webp" alt="Icon" title="Crypto 3" width="56" height="89" class="img-fluid"></span>
                     <span class="left-tp four"><img src="/assets/images/onHover-4.webp" alt="Icon" title="Crypto 4" width="87" height="86" class="img-fluid"></span>
                  </div>
                  <div class="content position-relative">
                     <h2 class="whitecolor">{{contentlist.get_started_content}}</h2>
                     <p class="whitecolor regular mb-0">{{contentlist.cta_three_content2}}</p>
                     <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="whiteBtn medium mt-4">{{contentlist.register_now_content}}</a>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
import { commonAllmixins } from '@/plugins/commonAll'   //anamica
   export default {
      mixins: [ commonAllmixins],   //anamica
   data() {
   return {
   tab: 1,
   };
   },
   };
</script>